 <aside id="left-panel">
     <nav>
         <br>
         <div class="profile-image-box">
             <div class="gold" [ngClass]="imageurl=='' || imageurl==undefined?'setnavBackgroundImg':''">
                <img *ngIf="imageurl!='' && imageurl!=undefined" [src]="imageurl ? imageurl:''" alt="Image"  class="body-image profile-pic-image">
             </div>
         </div>
         <div class="text-center"><label class="userlabel">{{userName}}</label></div>
         <div class="text-center mb-4"><label class="company-label" id="help_text" (click)="goToAdmin()">{{companyName}}</label></div>
         <ul saSmartMenu>
             <li routerLinkActive="active">
                 <a title="{{'profile_myProfile' | i18n}}" routerLink="/dashboard">
                     <i class="fas fa-user" style="font-size: 17px !important;"></i>
                     <span class="menu-item-parent pl-3">{{'Dashboard' | i18n}}</span></a>
             </li>
         </ul>
     </nav>

     <div class="navFooter">
         <div class="colDisplay">
             <div class="logoutSection">
                 <sa-clearCache></sa-clearCache>
             </div>
         </div>
     </div>
 </aside>