

export const languages: any = [
  {
    "key": "en",
    "alt": "common_language_English",
    "title": "common_language_English"
  },
  {
    "key": "fr",
    "alt": "common_language_French",
    "title": "common_language_French"
  },
  {
    "key": "de",
    "alt": "common_language_German",
    "title": "common_language_German"
  }
];
