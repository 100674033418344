import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { AuthGuard } from "./core/guards/auth.guard";
// import { RoleResolveService } from "./core/resolver/role-resolve.service";

const routes: Routes = [
  {
		path: "", redirectTo: 'auth', pathMatch: 'full',
	},
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle:"" },
    children: [{
      path: "dashboard",
      loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
      data: { pageTitle: "dashboard"},
      canActivate:[AuthGuard]   
    }]
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    // canActivate:[AuthGuard]
  },
  {
    path: "autologin",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/autologin/autologin.module').then(m => m.AutologinModule),
    // canActivate:[AuthGuard]
  },
  {
    path: "autologin/:path",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/autologin/autologin.module').then(m => m.AutologinModule),
    // canActivate:[AuthGuard]
  },
  {
    path: "privacy-policy",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    // canActivate:[AuthGuard]
  },
  {
    path: "termsandcondition",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/termsandcondition/termsandcondition.module').then(m => m.TermsandConditionModule),
    // canActivate:[AuthGuard]
  },
  {
    path: "legal-notices",
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/legal/legal.module').then(m => m.LegalModule),
    // canActivate:[AuthGuard]
  },
  { path: "**", redirectTo: "auth"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}