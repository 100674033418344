import {Injectable, ApplicationRef, EventEmitter, Output} from '@angular/core';

import {config} from '@app/core/smartadmin.config';
import {languages} from './languages.model';
import {JsonApiService} from "@app/core/services/json-api.service";
import { BehaviorSubject, Subject,Observable } from 'rxjs';
import { CommonService } from "@app/core/services/common.service";
import { forkJoin } from 'rxjs';

@Injectable()
export class I18nService {
  @Output() fire: EventEmitter<any> = new EventEmitter();

  public state;
  public data:{};
  public currentLanguage:any;
  private langChangeSubject: any;
  public langChangeEvent: any;
  public languages: Array<any>;
  languageLang={"German":"common_language_German","English":"common_language_English","French":"common_language_French"}
  constructor(private jsonApiService:JsonApiService, private ref:ApplicationRef, private commonService: CommonService) {
    this.state = new Subject();
    this.languages = languages;
    let userLanguage = localStorage.getEncryptedItem("userLanguage");
    let selected_lang = localStorage.getEncryptedItem("current_lang");
    let lang_data = localStorage.getEncryptedItem("lang_data");
    var langDataPresent = false;
    if (selected_lang && lang_data) {
      try {
        selected_lang = JSON.parse(selected_lang);
        lang_data = JSON.parse(lang_data);
        if (selected_lang["key"] === "en") {
          selected_lang["key"] = "en";
          localStorage.setEncryptedItem("current_lang", JSON.stringify(selected_lang));
        }
        langDataPresent = true;  
        this.currentLanguage = selected_lang;    
        this.data = lang_data;
      }
      catch (e) {
        throw "There is error in language parsing";
      }
    }
    else{
      this.currentLanguage = {key: "de", alt: "German", title: "German"};
      this.fetch(this.currentLanguage);
    }
    if (!langDataPresent) {
      this.initLanguage(userLanguage || 'de');
     
      //this.fetch(this.currentLanguage);
      
    }
    this.langChangeSubject = new BehaviorSubject<any>({});
    this.langChangeEvent = this.langChangeSubject.asObservable();
  }

  private fetch(locale: any) {
     setTimeout(() => {
      var key = locale.key;
        return forkJoin([this.jsonApiService.fetch(`/langs/${key}.json`),this.jsonApiService.fetch(`/langs/datatable/${key}.json`)]
        ).subscribe(res =>{
        this.data = res[0];
        localStorage.setEncryptedItem('lang_data', JSON.stringify(res[0]));
        localStorage.setEncryptedItem('dataTablelang_data', JSON.stringify(res[1]));
        localStorage.setEncryptedItem('current_lang', JSON.stringify(locale));
        this.currentLanguage=JSON.parse(JSON.stringify(locale));
        this.state.next(res[0]);
        this.ref.tick();
      });
      });
  }

  private initLanguage(locale:string) {
    let language = this.languages.find((it) => {
      return it.key == locale
    });
    if (language) {
      this.currentLanguage = language
    } else {
      throw new Error(`Incorrect locale used for I18nService: ${locale}`);

    }
  }
  getEmittedLanguageValue() {
    return this.fire;
  }

  setLanguage(language){
    this.fire.emit(language)

    this.currentLanguage = language;
    this.fetch(language)
  }

  setLanguageByKey(key: any) {
    let language = this.languages.find((it) => {
      return it.key == key
    });
    this.currentLanguage = language;
  }
  subscribe(sub:any, err:any) {
    return this.state.subscribe(sub, err)
  }

  public getTranslation(phrase:string):string {
    var current_lang = localStorage.getEncryptedItem('current_lang') ? JSON.parse(localStorage.getEncryptedItem('current_lang')) : (this.currentLanguage ? this.currentLanguage : {key: "de", alt: "German", title: "German"});
    if (this.currentLanguage && current_lang && this.currentLanguage.key !== current_lang.key) {
      try {
        this.currentLanguage = current_lang;
        this.data = JSON.parse(localStorage.getEncryptedItem('lang_data'));
        if(this.data == null && this.data == {} && this.data == undefined){
          this.fetch(this.currentLanguage);
          this.data = JSON.parse(localStorage.getEncryptedItem('lang_data'));
          this.fireLangChangeEvent();
        }
        else{
          this.fireLangChangeEvent();
        }
      }
      catch (e) {
        throw "There is some error in parsing language data" + phrase;
      }
    }
    return this.data && this.data[phrase] ? this.data[phrase] : phrase
  }
  public fireLangChangeEvent() {
    let langObj = { isHandled: false };
    this.langChangeSubject.next(langObj);
  }

  getLanguages() {
    let lang = this.commonService.getCurrentLanguageCode();
    let userLanguage = localStorage.getEncryptedItem("userLanguage");
    if(userLanguage && userLanguage != lang) {

      lang = userLanguage;
    }
  }
  setLanguageData(lang) {
    let languageArray = [];
    this.commonService.fetchLanguage(lang).subscribe(data => {
      let languageRes = data["LANGUAGES"];      
      // setTimeout(() => {
        for(let i=0;i<languageRes.length;i++){
          languageArray.push({key : languageRes[i].CODE, alt : this.languageLang[languageRes[i].NAME], title : this.languageLang[languageRes[i].NAME]});
        }
        this.languages = languageArray;
      // },1000);
    });
    return languageArray;
  }

}
