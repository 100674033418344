import {Component, OnInit} from '@angular/core';
import { UserService } from '@app/core/services';
import { NavigationService } from './navigation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from '@app/core/services/common.service';
import { environment } from '@env/environment';

@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
  styleUrls:['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  userName = "";
  accountId: any;
  imageurl;
  companyName: string;

  constructor(private userService:UserService, private navigationService:NavigationService,private _sanitizer: DomSanitizer,private commonService: CommonService) {
  }

  ngOnInit() {
    // this.userService.userData().subscribe(res=>{
    //   let user = res['data']
    //   this.userName = user[0].username;
    //   let permissions = this.commonService.encrypt(String(user[0].permissions.Allow));
    //   // let a = this.commonService.encrypt(permissions);
    //   localStorage.setEncryptedItem('permissions',permissions);
    //   localStorage.setEncryptedItem('userData', JSON.stringify(user));
    //   localStorage.setEncryptedItem('accId',user[0].accId);
    // });
    // this.accountId = '17';
    // this.userName = JSON.parse(localStorage.getEncryptedItem('userData'))[0].username;
    // let companyList = JSON.parse(localStorage.getEncryptedItem('userData'))[0].companyDetails;
    // this.companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'];
    let userCookie = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.loginCookie)));

    let userData = userCookie ? JSON.parse(userCookie) : "";
    this.userName = userData.firstName + ' ' +userData.name;
    // this.companyName = userData.companyName
    // let userInfo: any = {};
    // userInfo.userName = this.userName;
    // userInfo.companyName = this.companyName;
    // this.commonService.setCookie(this.commonService.encrypt(environment.userInfo),this.commonService.encrypt(JSON.stringify(this.userName)),10000,environment.cookieDomain,'/');
    let fetchProfileData = new FormData();
    this.accountId = localStorage.getEncryptedItem('accId');
    fetchProfileData.append("accountId",  this.accountId);
    let object = this.commonService.createObject(fetchProfileData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    // this.navigationService.getUserImg(encrData)
    // .subscribe(data=>{
    //   if(data['status'])
    //   this.imageurl=this._sanitizer.bypassSecurityTrustResourceUrl(data['data']);
    // })
    // this.changeProfile()
    // this.changeUserDeatils()
  }
  // changeProfile(){
  //   this.commonService.profilemImageChangeObservable
  //   .subscribe((profileState)=>{
  //     if(profileState){
  //       this.imageurl="";
  //       let fetchProfileData = new FormData();
  //       this.accountId = localStorage.getEncryptedItem('accId');
  //       fetchProfileData.append("accountId",  this.accountId);
  //       let object = this.commonService.createObject(fetchProfileData);
  //       let token = this.commonService.createToken(object);
  //       let encryptedToken = this.commonService.encrypt(token);
  //       let encrData = new FormData();
  //       encrData.append("encrToken", encryptedToken);
  //       this.navigationService.getUserImg(encrData)
  //       .subscribe(data=>{
  //         this.imageurl=""
  //         if(data['status']){
  //           this.imageurl=this._sanitizer.bypassSecurityTrustResourceUrl(data['data']);
  //         }                 
  //       })
  //     }
  //   })
  // }
  // changeUserDeatils(){
  //   let formData = new FormData();
  //   formData.append('code','ACCOUNTS')
  //   let objectUser = this.commonService.createObject(formData);
  //   let tokenUser = this.commonService.createToken(objectUser);
  //   let encryptedTokenUser = this.commonService.encrypt(tokenUser);
  //   let encrDataUser = new FormData();
  //   encrDataUser.append("encrToken", encryptedTokenUser);
  //   this.commonService.userDataChangeObservable
  //   .subscribe(()=>{
  //       this.userService.userData(encrDataUser).subscribe(res=>{
  //           let user = res['data']
  //           let userName = user[0].username;
  //           let companyList = user[0].companyDetails;
  //           let companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'];
  //           let userInfo = {};
  //           userInfo["userName"] = userName;
  //           userInfo["companyName"] = companyName;
  //           this.commonService.setCookie(this.commonService.encrypt(environment.userInfo),this.commonService.encrypt(JSON.stringify(userInfo)),10000,environment.cookieDomain,'/');
  //           this.userName = user[0].username
  //           localStorage.setEncryptedItem('userData', JSON.stringify(user));
  //           localStorage.setEncryptedItem('accId',user[0].accId);
  //         });     
  //   })
  // }

  goToAdmin() {
    // window.open(environment.ADMIN_URL, '_self')
    return false;
  }

}
